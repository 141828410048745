
gml.pages.home = {

    init() {
        console.log('homepage js is working!');

        // slick slider
        jQuery('.c-offices__testimonials').slick({
          slidesToShow: 1,
          speed: 1000,
          autoplay: false,
          dots: true,
          draggable: false,
          arrows: false,
        });
    },

    bindEvents() {

        // Accordion
        $('.js-accordion-trigger').bind('click', function(e) {
            $(this).parent().find('.submenu').slideToggle('fast');
           // apply the toggle to the ul
            $(this).parent().toggleClass('is-expanded');
            e.preventDefault();
        });

        // Tabs
        $('.accordion-tabs').each(function(index) {
            $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
        });

        $('.accordion-tabs').on('click', 'li > a.tab-link', function(event) {
            if (!$(this).hasClass('is-active')) {
                event.preventDefault();
                var accordionTabs = $(this).closest('.accordion-tabs');
                accordionTabs.find('.is-open').removeClass('is-open').hide();

                $(this).next().toggleClass('is-open').toggle();
                accordionTabs.find('.is-active').removeClass('is-active');
                $(this).addClass('is-active');
            } else {
                event.preventDefault();
            }
        });

        // Modal
        $(function() {
            $("#modal-1").on("change", function() {
                if ($(this).is(":checked")) {
                    $("body").addClass("modal-open");
                } else {
                    $("body").removeClass("modal-open");
                }
            });

            $(".modal-fade-screen, .modal-close").on("click", function() {
                $(".modal-state:checked").prop("checked", false).change();
            });

            $(".modal-inner").on("click", function(e) {
                e.stopPropagation();
            });
        });

    } // End Events

};
